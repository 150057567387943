<template>
  <v-dialog @input="close" :value="value" width="600">
    <v-card class="upload">
      <v-card-title class="headline" primary-title>
        <span>{{ $t("common.certifications.uploadDialog.title") }}</span>
      </v-card-title>
      <v-divider />

      <v-card-text class="my-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters>
            <!-- Type -->
            <v-col cols="12" class="mt-6">
              <v-autocomplete
                :rules="requiredRules"
                v-model="form.type"
                item-value="value"
                item-text="text"
                :items="$t('constants.certifications.list')"
                outlined
                :label="
                  $t(
                    'common.certifications.uploadDialog.certificationType.label'
                  )
                "
              />
            </v-col>

            <!-- Date start -->
            <v-col cols="6">
              <v-text-field
                v-model="form.date_start"
                outlined
                :label="
                  $t('common.certifications.uploadDialog.dateStart.label')
                "
                type="date"
                :placeholder="
                  $t('common.certifications.uploadDialog.dateStart.placeholder')
                "
              />
            </v-col>

            <!-- Date end -->
            <v-col cols="6">
              <v-text-field
                  required pattern="\d{4}-\d{2}-\d{2}"
                v-model="form.date_end"
                outlined
                :label="$t('common.certifications.uploadDialog.dateEnd.label')"
                type="date"
                :placeholder="
                  $t('common.certifications.uploadDialog.dateEnd.placeholder')
                "
                :rules="requiredRules"
              />
            </v-col>

            <!-- State -->
            <v-col cols="12" class="mt-2">
              <v-select
                outlined
                v-model="form.status"
                :items="$t('constants.certificationsStatus.list')"
                item-text="text"
                item-value="value"
                :label="$t('common.certifications.uploadDialog.status.label')"
              />
            </v-col>

            <!-- Files -->
            <v-col cols="12" class="mt-6" v-if="!item">
              <div class="upload">
                <div v-if="files.length > 0" class="mt-6">
                  <v-row justify="space-between" align="center" class="mx-4">
                    <div class="subtitle-1">{{ files[0].name }}</div>
                    <div>
                      <v-btn icon @click="clearFile">
                        <v-icon>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </div>

                <div class="upload-btn" v-if="files.length === 0">
                  <FileUpload
                    class="button-upload"
                    post-action="/upload/post"
                    :multiple="false"
                    :drop="true"
                    :drop-directory="true"
                    v-model="files"
                    ref="upload"
                  >
                    <v-card>
                      <v-row align="center" justify="center" no-gutters>
                        <span class="title">{{
                          $t("common.files.drop_file")
                        }}</span>
                        <v-btn
                          text
                          outlined
                          class="ml-2"
                          style="cursor: pointer !important;"
                        >
                          <span>{{ $t("common.files.uploadBtn") }}</span>
                        </v-btn>
                      </v-row>
                    </v-card>
                  </FileUpload>
                </div>
              </div>
            </v-col>

            <!-- Is visible -->
            <v-col cols="12" class="mt-2">
              <v-switch
                outlined
                v-model="form.isVisible"
                :label="$t('common.certifications.uploadDialog.isVisible.label')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-3"
          :is-loading-btn-save="isLoadingBtn.btnSave"
          @submit="customSubmit"
          @cancel="close"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "vue-upload-component";

// Mixins
import dialogMixin from "@/mixins/dialog";
import fileMixin from "@/mixins/file";
import formRules from "@/mixins/formRules";

export default {
  name: "UploadCertificationDialog",

  props: {
    URL_API: { type: String, default: "" },
  },

  mixins: [fileMixin, formRules, dialogMixin],

  components: {
    ButtonBarFormDialog: () =>
      import("@/components/Common/Buttons/ButtonBarFormDialog"),
    FileUpload,
  },

  data() {
    return {
      isLoadingBtn: { btnSave: false },
      files: [],
      defaultForm: {
        type: null,
        date_start: null,
        date_end: null,
        status: "NOT_VERIFIED",
        isVisible: true
      },
    };
  },
  mounted() {
    this.form.date_end = this.form.date_end ? this.form.date_end.substring(0, 10) : null;
    this.form.date_start = this.form.date_start ? this.form.date_start.substring(0, 10) : null;
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.form.date_end = this.form.date_end ? new Date(this.form.date_end).toISOString() : null;
        this.form.date_start = this.form.date_start ? new Date(this.form.date_start).toISOString() : null;
        if (this.item) this.submitEdit();
        else this.submitUpload();
      }
    },

    close(needRefresh = false) {
      this.files = [];
      this.$store.commit("alert/hideAlert");
      this.form = { ...this.defaultForm };
      this.$emit("close", needRefresh);
    },

    submitUpload() {
      // if (this.files.length > 0) {

      const formData = new FormData();
      if (this.files && this.files.length > 0)
        formData.append("file", this.files[0].file);
      formData.append("type", this.form.type);
      formData.append("date_start", this.form.date_start);
      formData.append("date_end", this.form.date_end);
      formData.append("status", this.form.status);
      formData.append("isVisible", Boolean(this.form.isVisible));

      this.$http
        .post(this.URL_API, formData, {
          headers: {
            Authorization: "Bearer " + this.$session.get("jwt"),
            "content-type": "multipart/form-data",
          },
        })
        .then(res => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("common.certifications.uploadDialog.success")
          );
          this.$emit("close", true);
        })
        .catch(err => {
          this.$store.commit(
            "toast/showError",
            this.$t("common.certifications.uploadDialog.error")
          );
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
      // }
      /*else {
          this.isLoadingBtn.btnSave = false;
          this.$store.commit("toast/showError", this.$t('common.files.importDialog.ERROR_NO_FILE'));
        }*/
    },

    submitEdit() {
      // console.log(this.item);
      this.form.isVisible = Boolean(this.form.isVisible);
      this.$http
        .put(`${this.URL_API}/${this.item.id}`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then(res => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("common.certifications.uploadDialog.success")
          );
          this.close(true);
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    clearFile() {
      this.files = [];
    },

    onInputDateStart(val) {
      this.form.date_start = val;
    },

    onInputDateEnd(val) {
      this.form.date_end = val;
    },
  },
};
</script>

<style scoped>
span.button-upload.file-uploads {
  width: 100%;
}

.upload label {
  margin-bottom: 0;
  margin-right: 1rem;
  width: 100%;
}

.upload .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.upload .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.button-upload {
}
</style>
